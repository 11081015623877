import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, {
  useContext,
} from 'react';
import styled from 'styled-components';

import { TvMain } from '@powdr/components/tv';
import { ThemeProps as Prop, StaticProfiles } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { themeUtil } from '@powdr/utils';

import GlobalStyle from '../../utils/global-styles';

const SiteWrapper = styled.div`
  height: 100vh;
  background-color: ${() => themeUtil(Prop.BACKGROUND, null, StaticProfiles.TV)};
`;

function TvStaticLayout({
  data,
}) {
  const { defaultTheme } = useContext(AppContext);

  const {
    name,
  } = data.pageSettings;

  return (
    <>
      <GlobalStyle theme={defaultTheme} />
      <SiteWrapper>
        <TvMain
          name={name}
          // lastUpdateTime="11:30 AM"
        />
      </SiteWrapper>
    </>
  );
}

TvStaticLayout.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TvStaticLayout;

export const query = graphql`
  query TvStaticLayout($fullPath: String){
    site {
      ...SiteMetadataQuery
    }
    pageSettings: taxonomyTermTvNavigation( field_navigation_link: { eq: $fullPath } ) {
      ...TvPageQuery
    }
  }
`;
